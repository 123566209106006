import { INIT, SEARCH } from "./action-types";
import {
  SET_ENTITIES,
  SET_IS_INITIALIZED,
  SET_IS_LOADING
} from "./mutation-types";

export default apiModule => ({
  async [INIT]({ state, commit }, payload) {
    commit(SET_IS_LOADING, true);
    const { entities, result } = await apiModule.fetchAll({
      filters: Object.assign(state.filters, payload.filters),
      includes: payload.includes
    });
    commit(SET_ENTITIES, { entities, ids: result });
    if (!state.isInitialized) {
      commit(SET_IS_INITIALIZED, false);
    }
    commit(SET_IS_LOADING, false);
    return result;
  },
  async [SEARCH]({ state, commit }, payload) {
    commit(SET_IS_LOADING, true);
    const { entities, result } = await apiModule.fetchAll({
      filters: payload.filters,
      includes: payload.includes
    });
    commit(SET_ENTITIES, { entities, ids: result });
    if (!state.isInitialized) {
      commit(SET_IS_INITIALIZED, false);
    }
    commit(SET_IS_LOADING, false);
    return result;
  }
});
