import Vue from "vue";
import {
  SET_IS_LOADING,
  SET_ENTITIES,
  SET_IS_INITIALIZED,
  SET_SEARCH_FILTER,
  SET_ASYNC_CACHE,
  SET_FILTER
} from "./mutation-types";
import { singleSetEntities } from "../entities";

export default {
  [SET_ENTITIES](state, { entities, ids }) {
    Vue.set(state, "ids", ids);
    singleSetEntities(state, entities);
  },
  [SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_IS_INITIALIZED](state) {
    state.isInitialized = true;
  },
  [SET_SEARCH_FILTER](state, search) {
    state.filters.search = search;
  },
  [SET_FILTER](state, filter) {
    Vue.set(
      state.filters,
      Object.keys(filter)[0],
      filter[Object.keys(filter)[0]]
    );
  },
  [SET_ASYNC_CACHE](state, currentSelection) {
    state.asyncCache = currentSelection;
  }
};
