import {
  IS_ASYNC,
  IS_INITIALIZED,
  IS_LOADING,
  ITEMS,
  ASYNC_CACHE
} from "./getter-types";

export default {
  [IS_INITIALIZED]: state => state.isInitialized,
  [IS_LOADING]: state => state.isLoading,
  [IS_ASYNC]: state => state.isAsync,
  [ASYNC_CACHE]: state => state.asyncCache,
  [ITEMS]: state => state.ids.map(entityId => state.entities.entity[entityId])
};
