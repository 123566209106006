import { createSelectEntityPlaceholders } from "@/store/helpers";

export default (entities = [], isAsync = false) => ({
  ...createSelectEntityPlaceholders(entities),
  asyncCache: [],
  ids: [],
  isLoading: false,
  isInitialized: false,
  isAsync: isAsync,
  filters: {}
});
